export interface Notification {
  progress?: string;
  position?: string;
  title?: string;
  duration?: number;
  text?: string;
  icon?: string;
  color?: string;
  loading?: boolean;
}

export function errorNotify(err: any): Notification {
  return {
    progress: 'auto',
    position: 'bottom-right',
    title: err.error,
    text: err.message,
    duration: 12000,
    // icon: " <i class='fas fa-exclamation-circle'></i>",
    color: 'danger',
  };
}

export function successNotify(): Notification {
  return {
    progress: 'auto',
    position: 'bottom-right',
    title: 'Updated',
    duration: 8000,
    // icon: " <i class='fas fa-exclamation-circle'></i>",
    color: 'success',
  };
}

export function savedNotify(entity: string): Notification {
  return {
    progress: 'auto',
    position: 'bottom-right',
    title: `${entity} saved`,
    text: 'Your changes have been saved',
    duration: 8000,
    // icon: " <i class='fas fa-exclamation-circle'></i>",
    color: 'success',
  };
}
export function saving(): Notification {
  return {
    loading: true,
    color: 'warn',
    position: 'bottom-right',
    title: 'Documentation Vuesax 4.0+',
    text: `These documents refer to the latest version of vuesax (4.0+),
            to see the documents of the previous versions you can do it here 👉 Vuesax3.x`,
  };
}

export function uploadNotify() {
  return {
    progress: 'auto',
    position: 'bottom-right',
    title: 'Upload Successful',
    text: 'Your documents have been uploaded.',
    duration: 8000,
    // icon: " <i class='fas fa-exclamation-circle'></i>",
    color: 'success',
  };
}

export function updateN() {
  return {
    progress: 'auto',
    position: 'bottom-right',
    title: 'Update Successful',
    duration: 8000,
    // icon: " <i class='fas fa-exclamation-circle'></i>",
    color: 'success',
  };
}

export function deleteNotify() {
  return {
    progress: 'auto',
    position: 'bottom-right',
    title: 'Deleted Successfully',
    text: 'Your documents has been deleted.',
    duration: 8000,
    // icon: " <i class='fas fa-exclamation-circle'></i>",
    color: 'success',
  };
}

export function selectionNotify(field: string): Notification {
  return {
    progress: 'auto',
    position: 'top-right',
    title: `${field} missing`,
    text: `Please select a ${field} before continuing`,
    duration: 8000,
    // icon: " <i class='fas fa-exclamation-circle'></i>",
    // color: "danger",
  };
}

export function messageSent(message: any) {
  return {
    progress: 'auto',
    position: 'bottom-right',
    title: `${message} sent`,
    text: `Your ${message} has been sent`,
    duration: 8000,
    // icon: " <i class='fas fa-exclamation-circle'></i>",
    color: 'success',
  };
}
