export default [
  {
    component: 'div',
    class: 'flex justify-end w-full mt-2',
    children: [
      {
        name: 'submit',
        type: 'submit',
        label: 'Next',
      },
    ],
  },
];
