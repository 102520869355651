export default [
  {
    type: 'group',
    name: 'minor',
    children: [
      {
        type: 'text',
        name: 'firstName',
        'keep-model-data': true,
        label: 'First name',
      },
      {
        type: 'text',
        name: 'lastName',
        'keep-model-data': true,
        label: 'Last name',
      },
    ],
  },
];
