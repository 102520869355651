export default [
  {
    component: 'div',
    class: 'grid grid-cols-2 gap-1',
    children: [
      {
        type: 'text',
        name: 'name',
        label: 'Organisation name',
        placeholder: 'organisation name',
        validation: 'required',
        class: 'col-span-1',
        'input-class': 'mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm border-gray-300 rounded',
        'validation-name': 'Name',
      },

      {
        type: 'text',
        name: 'website',
        label: 'Organisation website',
        placeholder: 'organisation website',
        validation: 'required',
        class: 'col-span-1',
        'input-class': 'mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm border-gray-300 rounded',
        'validation-name': 'Website',
      },
      {
        type: 'countryCode',
        label: 'Phone number',
        name: 'phone',
      },
      // {
      //   type: 'submit',
      //   label: 'Sign in',
      //   class: 'invisible',
      //   ref: 'accountSubmit',
      // },
    ],
  },
];
