export default [
  {
    component: 'div',
    children: [
      {
        type: 'text',
        name: 'name',
        label: 'Organisation name',
        placeholder: 'organisation name',
        validation: 'required',
        class: 'col-span-1',
        'input-class': 'mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm border-gray-300 rounded',
        'validation-name': 'Name',
      },
      {
        type: 'text',
        name: 'website',
        label: 'Website',
        placeholder: 'Website',
        validation: 'required',
        class: 'col-span-1',
        'input-class': 'mt-1 d focus:ring-red-500 focus:border-red-500 block w-full shadow-sm border-gray-300 rounded',
        'validation-name': 'Website',
      },
      {
        type: 'countryCode',
        label: 'Phone number',
        name: 'phone',
        'keep-model-data': true,
        'validation-messages': {
          phone: 'Phone is required',
        },
        'error-behaviour': 'blur',
        validation: 'required|phone',
      },
      {
        type: 'submit',
        label: 'Save account',
        class: 'invisible',
        ref: 'accountSubmit',
      },
    ],
  },
];
