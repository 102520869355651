export default [
  {
    component: 'div',
    class: 'wrap',
    children: [
      {
        type: 'number',
        label: 'Temperature',
        name: 'temperature',
        'keep-model-data': true,
      },
      {
        type: 'text',
        label: 'Room number',
        name: 'roomNumber',
        'keep-model-data': true,
      },
      {
        component: 'div',
        class: 'flex-row',
        key: 'check-key',
        children: [
          {
            type: 'checkbox',
            label: 'Cough',
            'keep-model-data': true,
            name: 'cough',
            class: 'flex-col',
          },
          {
            type: 'checkbox',
            label: 'Soar throat',
            'keep-model-data': true,
            name: 'soreThroat',
            class: 'flex-col',
          },
          {
            type: 'checkbox',
            label: 'Staff name',
            'keep-model-data': true,
            name: 'staffName',
            class: 'flex-col',
          },
          {
            type: 'checkbox',
            label: 'Shortness of breath',
            'keep-model-data': true,
            name: 'shortnessBreath',
            class: 'flex-col',
          },
          {
            type: 'checkbox',
            label: 'Briefing given',
            'keep-model-data': true,
            name: 'briefingGiven',
            class: 'flex-col',
          },
        ],
      },
      {
        type: 'text',
        label: 'Staff name',
        name: 'staffName',
        'keep-model-data': true,
      },
      {
        type: 'checkbox',
        label: 'Staff accepted',
        'keep-model-data': true,
        name: 'staffAccepted',
      },
      {
        type: 'checkbox',
        label: 'Guest accepted',
        'keep-model-data': true,
        name: 'guestAccepted',
      },
    ],
  },
];
