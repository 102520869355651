import Component, { mixins } from 'vue-class-component';
import contactSchema from '@/modules/accommodation/schema/contact.schema';
import guestInfoSchema from '@/modules/guests/schema/guest-info.schema';
import guestSchema from '@/modules/guests/schema/guest.schema';
import minorInfo from '@/modules/guests/schema/minor-info.schema';
import minorSchema from '@/modules/guests/schema/minor.schema';
import billingSchema from '@/modules/organisation/schema/billing.schema';
import organisationSchema from '@/modules/organisation/schema/organisation.schema';
import submitSchema from '@/shared/schema/submit.schema';
import LoadMixin from './load.mixin';
import registerSchema from '../../modules/organisation/schema/register.schema';

@Component
export default class SchemaMixin extends mixins(LoadMixin) {
  contactSchema = contactSchema;

  organisationSchema = organisationSchema;

  billingSchema = billingSchema;

  guestSchema = guestSchema;

  guestInfoSchema = guestInfoSchema;

  submitSchema = submitSchema;

  registerSchema = registerSchema;

  minorSchema = minorSchema;

  minorInfo = minorInfo;

  getFlatSchema(schema: any) {
    return this.flatMapper(schema);
  }

  flatMapper(obj: any) {
    if (obj.flatMap) return obj.flatMap((val: any) => (val.children ? this.flatMapper(val.children) : val.name ?? null));
  }

  isIterable(obj: any) {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }
  //
  map(key: string, val: Record<string, any>): any {
    if (this.isIterable(val)) {
      {
        return Object.keys(val).filter((v) => {
          if (this.isIterable(val[v])) {
            return this.map(key, val[v]);
          } else {
            return v === key ? val[v] : false;
          }
        });
      }
    }
  }

  getHashMap(arr: [{ code: any; number: any }]) {
    return this.isIterable(arr) ? Object.assign(...arr) : arr;
  }
}
