import countries from '@/app/config/world.json';

export default [
  {
    component: 'div',
    class: 'grid grid-cols-6 gap-6 px-4 py-5 sm:p-6',
    children: [
      // {
      //   type: 'text',
      //   name: 'firstName',
      //   label: 'First name',
      //   validation: 'required',
      //   'validation-name': 'Name',
      //   class: 'col-span-6 sm:col-span-3',
      //   'keep-model-data': true,
      // },
      // {
      //   type: 'text',
      //   name: 'lastName',
      //   label: 'Last name',
      //   validation: 'required',
      //   class: 'col-span-6 sm:col-span-3',
      //   'validation-name': 'Last name',
      //   'keep-model-data': true,
      // },
      // {
      //   type: 'email',
      //   name: 'email',
      //   label: 'Email',
      //   validation: 'required',
      //   'validation-name': 'Email',
      //   class: 'col-span-6 sm:col-span-3',
      //   'keep-model-data': true,
      // },

      {
        type: 'text',
        label: 'Medical Aid Provider',
        'keep-model-data': true,
        placeholder: 'Medical Aid Provider',
        class: 'col-span-6 sm:col-span-3',
        name: 'medicalAidProvider',
      },
      {
        type: 'text',
        label: 'Medical Aid Number',
        name: 'medicalAidNumber',
        placeholder: 'Medical Aid Number',
        class: 'col-span-6 sm:col-span-3',
        'keep-model-data': true,
      },
      {
        label: 'Nationality',
        name: 'nationality',
        placeholder: 'Choose nationality',
        'keep-model-data': true,
        type: 'select',
        value: 'South africa',
        options: countries,
        class: 'col-span-6 sm:col-span-3',
      },
      {
        label: 'Passport or ID No.',
        name: 'passportId',
        placeholder: 'Passport or ID No.',
        'validation-name': 'Passport/ID',
        'keep-model-data': true,
        class: 'col-span-6 sm:col-span-3',
      },
      {
        type: 'datepicker',
        label: 'Date of birth',
        name: 'dateOfBirth',
        class: 'col-span-6 sm:col-span-3',
      },

      {
        type: 'text',
        label: 'Medical Aid Emergency contact number',
        name: 'medicalAidEmergency',
        placeholder: 'Medical Aid Emergency contact number',
        class: 'col-span-6 sm:col-span-3',
        'keep-model-data': true,
      },

      {
        label: 'Nationality',
        name: 'nationality',
        placeholder: 'Choose your nationality',
        'keep-model-data': true,
        type: 'select',
        value: 'South africa',
        options: countries,
        class: 'col-span-6 sm:col-span-3',
      },
      {
        label: 'Passport or ID number',
        name: 'passportId',
        placeholder: 'Passport or ID number',
        'validation-name': 'Passport/ID',
        'keep-model-data': true,
        class: 'col-span-6 sm:col-span-3',
      },
      {
        type: 'date',
        label: 'Date of birth',
        name: 'dateOfBirth',
        class: 'col-span-6 sm:col-span-3',
      },

      {
        type: 'text',
        label: 'Emergency contact number',
        'keep-model-data': true,
        placeholder: 'In case of emergency contact number',
        class: 'col-span-6 sm:col-span-3',
        name: 'emergencyContact',
      },
      {
        type: 'checkbox',
        label: 'Do you have travel Insurance?',
        'keep-model-data': true,
        class: 'col-span-6 sm:col-span-3',
        name: 'travelInsurance',
      },
      //   {
      //     type: 'checkbox',
      //     label: 'Would you like us to handle your luggage?',
      //     'keep-model-data': true,
      //     class: 'col-span-6 sm:col-span-3',
      //     name: 'luggageHandling',
      //     //   },
      //     //   {
      //     //     type: 'checkbox',
      //     //     label: 'Do you have travel Insurance?',
      //     //     'keep-model-data': true,
      //     //     class: 'col-span-6 sm:col-span-3',
      //     //     name: 'travelInsurance',
      //   },
    ],
  },
];
