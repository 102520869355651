export default [
  {
    component: 'div',
    class: 'form-wrap',
    children: [
      {
        type: 'text',
        name: 'firstName',
        'keep-model-data': true,
        label: 'First name',
      },
      {
        type: 'text',
        name: 'lastName',
        'keep-model-data': true,
        label: 'Last name',
      },
      {
        type: 'text',
        name: 'nationality',
        label: 'Nationality',
      },
      {
        type: 'text',
        name: 'passport',
        label: 'Passport',
      },
      {
        type: 'text',
        name: 'rsaId',
        label: 'RSA ID',
      },
      {
        type: 'datepicker',
        name: 'dateOfBirth',
        label: 'Date of birth',
      },
    ],
  },
];
