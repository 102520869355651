
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Ref } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import client from '@/shared/plugins/content';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Component({
  components: {
    VuePerfectScrollbar,
  },
})
export default class TermsModalComponent extends Vue {
  @PropSync('popupActive') popupActiveSync!: any;

  @Prop() isAccepted!: any;

  @Ref('ref') readonly ref: any;

  settings = {
    maxScrollbarLength: 60,
  };

  title = '';

  terms = '';

  disabled = true;

  accept = false;

  mounted() {
    client
      .getEntry('6d56tOFvYMXV9Xosf8ECrH')
      .then((entry: any) => {
        const richText = entry.fields.company;

        return documentToHtmlString(richText);
      })
      .then((renderedHtml: any) => {
        const html = document.getElementById('rich-text-body');
        if (html) html.innerHTML = renderedHtml;
      });
  }

  acceptFunc() {
    this.disabled = false;
  }

  scrollUp() {
    this.disabled = true;
  }

  closeModal() {
    if (!this.disabled) {
      this.popupActiveSync = false;
      //   this.$router.go(-1);
    }
  }
}
