export default [
  {
    component: 'div',
    class: 'grid grid-cols-2 gap-2',
    children: [
      {
        type: 'text',
        name: 'billingCompany',
        label: 'Billing Company',
        placeholder: 'Company name',
        validation: 'required',
        class: 'col-span-1',
        'input-class': 'mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm border-gray-300 rounded',
        'validation-name': 'company name',
      },
      {
        type: 'text',
        name: 'billingContact',
        label: 'Billing Contact Person',
        placeholder: 'Contact person name',
        // validation: 'required',
        class: 'col-span-1',
        'input-class': 'mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm border-gray-300 rounded',
        'validation-name': 'Billing contact',
      },
      {
        type: 'email',
        name: 'billingEmail',
        label: 'Billing Email',
        placeholder: 'Email address',
        validation: 'required|email',
        class: 'col-span-1',
        'input-class': 'mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm border-gray-300 rounded',
        'validation-name': 'Email',
      },
      {
        type: 'countryCode',
        label: 'Phone number',
        name: 'billingPhone',
      },
      {
        name: 'organisationReg',
        label: 'Company Registration',
        placeholder: 'Organisation Registration',
        'validation-name': 'organisation Registration',
        class: 'col-span-1 w-full',
        // validation: 'required',
      },
      {
        name: 'vatNo',
        label: 'Vat number',
        placeholder: 'Vat number',
        validation: 'required',
        'validation-name': 'Vat number',
        class: 'col-span-1 w-full',
      },
      {
        name: 'billingAddress',
        label: 'Billing Address',
        type: 'textarea',
        ref: 'Billing address',
        class: 'col-span-1 w-full',
        // types: '(regions)',
        country: 'ZA',
        // placeholder: 'Address one',
        // validation: 'required',
        // 'validation-name': 'Address one',
      },
      // {
      //   type: 'submit',
      //   label: 'Sign in',
      //   class: 'invisible',
      // },
    ],
  },
];
