
import Component from 'vue-class-component';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Vue from 'vue';
import dailySchema from '@/modules/guests/schema/daily-check';

@Component
export default class GuestDailyForm extends Vue {
  schema = dailySchema;

  model = {};

  pop = true;
}
