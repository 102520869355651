function guestSchema(guest: any) {
  return [
    {
      type: 'text',
      name: 'firstName',
      label: 'First name',
      validation: 'required',
      id: 'firstName',
      'validation-name': 'Name',
      'keep-model-data': true,
      disabled: guest.status !== 'PENDING',
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Last name',
      validation: 'required',
      'keep-model-data': true,
      'validation-name': 'Last name',
      disabled: guest.status !== 'PENDING',
    },
    {
      type: 'countryCode',
      label: 'Mobile Number (SMS invite will be sent to the guest)',
      name: 'phone',
      'keep-model-data': true,
      disable: guest.status !== 'PENDING',
    },

    {
      type: 'label',
      label: 'Or',
    },
    {
      type: 'email',
      name: 'email',
      label: 'Guest Email (email invite will be sent to the guest)',
      validation: 'invite|email|optional',
      'keep-model-data': true,
      'validation-name': 'Email',
      disabled: guest.status !== 'PENDING',
    },
  ];
}

export default guestSchema;
