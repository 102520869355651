<route>
{
  "meta": {
    "requiresAuth": true,
    "resource": "tenant"
  }
}
</route>

<template>
  <div class="organisation">
    <OrganisationRegisterView />
  </div>
</template>

<script>
// @ is an alias to /src
import OrganisationRegisterView from '@/modules/organisation/pages/OrganisationRegisterView.vue';

export default {
  name: 'Register',
  components: {
    OrganisationRegisterView,
  },
  layout: 'AppFullPage',
};
</script>
