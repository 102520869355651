
import Component, { mixins } from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import GuestDailyForm from '@/modules/guests/components/GuestDailyForm.vue';
import { ITenantDto } from '@/app/entities';
import SchemaMixin from '@/shared/mixins/schema.mixins';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import OrganisationPaymentModal from '@/modules/organisation/components/Pricing.vue';
import contentModule from '@/store/modules/content.module';
import OrganisationPayment from '@/modules/organisation/pages/OrganisationPaymentView.vue';
import SubscriptionPanel from '@/modules/organisation/components/SubscriptionPanel.vue';
import userModule from '@/store/modules/user/user.module';
import { Loading, Loader } from '@/modules/auth/store/auth.service';
import Routes from '@/shared/constants/routes';
import TenantMembersComponent from '@/modules/organisation/components/OrganisationMemberList.vue';
import isEmpty from '@/app/utils/object.utils';
import dayjs from 'dayjs';
import { buildAbilityFor } from '@/plugins/casl/ability';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  components: {
    GuestDailyForm,
    OrganisationPayment,
    OrganisationPaymentModal,
    SubscriptionPanel,
    TenantMembersComponent,
  },
})
export default class OrganisationProfile extends mixins(SchemaMixin) {
  @Ref('acc') readonly acc: FormulateForm;

  @Ref('bill') readonly bill: FormulateForm;

  @Ref('address') readonly address: FormulateForm;

  //   @Prop({ default: false }) isNew!: boolean;

  activeTab = 0;

  addressInfo = '';

  isActive = false;

  addMembers = false;

  isRegister = false;

  tenant: ITenantDto = {} as ITenantDto;

  cont = contentModule.subContent;

  path = this.$route.path;

  loading = true;

  get content() {
    return this.cont;
  }

  set content(content) {
    this.cont = content;
  }

  get addCancel() {
    return this.addMembers ? 'Cancel' : 'Add members';
  }

  async created(): Promise<void> {
    this.isRegister = this.$route.path === '/register';
    if (!isEmpty(tenantModule.tenant)) {
      if (Object.keys(tenantModule.tenant).length !== 0) {
        await tenantModule.getTenant();
        this.loading = false;
        this.tenant = tenantModule.tenant as ITenantDto;
      }
    }
    this.loading = false;
  }

  get cached() {
    return this.$store.getters['organisation/isCached'];
  }

  get subtitle(): string {
    if (this.cached) {
      return ' * You have unsaved changes';
    }
    return '';
  }

  get isNew(): boolean | null | undefined {
    return !userModule.user;
  }

  getAddressData(addressData: string): void {
    this.addressInfo = addressData;
  }

  get billing(): string {
    return this.$store.state.billingInformation;
  }

  @Loading
  async handle(): Promise<any> {
    const validationCheck = this.acc.formSubmitted() && this.bill.formSubmitted();
    if (validationCheck) {
      return this.updateTenant();
    }
    return validationCheck;
  }

  @Loader('Creating your new organisation')
  async updateTenant(): Promise<any> {
    const payload = this.tenant;
    payload.phone = this.getHashMap(this.tenant.phone);
    payload.billing.billingPhone = this.getHashMap(this.tenant.billing.billingPhone);

    if (this.tenant.id) {
      await tenantModule.updateTenant(payload);
      this.tenant = tenantModule.tenant as ITenantDto;
      this.$swal('Updated!', 'Your account details have been updated.', 'success');
      return true;
    }
    await tenantModule.createTenant(payload);

    buildAbilityFor(userModule.member[0].role.ability as any);
    this.$ability.update(userModule.member[0].role.ability as any);

    return this.$router.push(Routes.ORGANISATION_DASHBOARD);
  }
}
