import Vue from 'vue';
import Component from 'vue-class-component';
import { errorNotify } from '../constants';

@Component
export default class LoadMixin extends Vue {
  loading: any = {};

  start(): void {
    this.loading = this.$vs.loading({
      type: 'circles',
      color: '#EA5455',
    });
  }

  stop(): void {
    this.loading.close();
  }

  error(err: any): void {
    // this.stop();
    this.$emitter.emit('error', errorNotify(err));
  }
}
