
import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import PdfViewer from '@/modules/guests/components/PdfViewer.vue';
import client from '@/shared/plugins/content';
import TermsModalComponent from '@/components/termsmodal/TermsModal.vue';
import SubscriptionPanel from '@/modules/organisation/components/SubscriptionPanel.vue';
import contentModule from '@/store/modules/content.module';
import OrganisationProfile from '@/modules/organisation/pages/OrganisationProfileView.vue';
import Graphic from '@/assets/images/svg/register.svg';
import { ITenantDto } from '../../../app/entities';
import { mixins } from 'vue-class-component';
import SchemaMixin from '../../../shared/mixins/schema.mixins';
import { buildAbilityFor } from '../../../plugins/casl/ability';
import Routes from '../../../shared/constants/routes';
import { tenantModule } from '../../../store/modules/tenant/tenant.module';
import userModule from '../../../store/modules/user/user.module';
import { Loader } from '../../auth/store/auth.service';

@Component({
  components: {
    OrganisationProfile,
    PdfViewer,
    VuePerfectScrollbar,
    TermsModalComponent,
    SubscriptionPanel,
    Graphic,
  },
})
export default class OrganisationRegister extends mixins(SchemaMixin) {
  @Ref('profile') readonly profile: any;

  popupActive = false;

  tenant: ITenantDto = {} as ITenantDto;

  title = '';

  accept = false;

  scrolledToBottom = false;

  addMember = true;

  client = client;

  phone = '';

  get regActive() {
    return !this.popupActive;
  }

  get termsAccepted() {
    return !this.accept && !this.scrolledToBottom;
  }

  set termsAccepted(_isAccepted) {
    this.accept = true;
    this.scrolledToBottom = true;
  }

  acceptFunc() {
    this.scrolledToBottom = true;
  }

  scrollUp() {
    this.scrolledToBottom = false;
  }

  closeModal() {
    if (this.scrolledToBottom && this.accept) {
      this.popupActive = false;
    }
  }

  // created() {
  // userModule.IS_NEW(true);
  // this.$emitter.on('regComplete', (event: any) => {
  //   userModule.notNew();
  //   event.push('/dashboard');
  // });
  // }

  get content() {
    return contentModule.subContent;
  }

  route(): void {
    this.$router.push('/dashboard');
  }

  @Loader('Creating your new organisation')
  async updateTenant(): Promise<any> {
    const payload = this.tenant;
    payload.phone = this.getHashMap(this.tenant.phone);

    await tenantModule.createTenant(payload);

    buildAbilityFor(userModule.member[0].role.ability as any);
    this.$ability.update(userModule.member[0].role.ability as any);

    return this.$router.push(Routes.ORGANISATION_DASHBOARD);
  }
}
